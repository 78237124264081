import React from "react";
import Data from "src/languageData/-Data.js";

const Footer = ( props ) => {
  return (
    <div className="footer">
      <div className="padding-global">
        <div className="footer__links">
          <div className="footer__link">
            <a href="/privacy-policy">{Data[ props.lang ].privacyTitle}</a>
          </div>
          <div className="footer__link">
            <a href="/data-deletion">{Data[ props.lang ].dataDeletionTitle}</a>
          </div>
        </div>
        <div className="copyright">Copyright © 2025, Gofilex. KvK: 9024239. All Rights Reserved.</div>
      </div>
    </div>
  );
};

export default Footer;
